import moment from 'moment';
import { Vue } from 'vue-property-decorator';
const DATE_FORMAT = 'MMM D, YYYY H:mmA';
export const TimeOut = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};
class UtilsService extends Vue {
    checkDate(date) {
        return date && date.length ? moment(+date).format(DATE_FORMAT) : '';
    }
    statusColorView(status) {
        switch (status) {
            case 'In Progress':
                return 'blue';
            case 'Completed':
                return 'green';
            case 'Aborted':
                return 'orange';
            case 'Exception':
                return 'purple';
            default:
                return 'blue';
        }
    }
    progressStatus(status) {
        switch (status) {
            case 'In Progress':
                return 'waiting-colored';
            case 'Completed':
                return 'success';
            case 'Aborted':
                return 'aborted';
            case 'Exception':
                return 'attention';
            default:
                return 'waiting-colored';
        }
    }
    jobStatusColor(status) {
        switch (status) {
            case 'In Progress':
                return '#00a1e0';
            case 'Completed':
                return '#27ae60';
            case 'Aborted':
                return '#ff7800';
            case 'Exception':
                return '#df0e77';
            default:
                return '#27ae60';
        }
    }
    randomID() {
        return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
    }
    showToast(message, variant, title) {
        return this.$bvToast.toast(message, {
            title,
            variant,
            solid: true,
            toaster: 'b-toaster-top-center',
        });
    }
    bytesToSizeString(bytes) {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (!bytes)
            return '0 Byte';
        const i = +Math.floor(Math.log(bytes) / Math.log(1024));
        return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
    }
    bytesToSizeNumber(bytes) {
        const i = +Math.floor(Math.log(bytes) / Math.log(1024));
        return Math.round(bytes / Math.pow(1024, i));
    }
    async showConfirm(message = 'Are you sure?') {
        try {
            return ((await this.$bvModal.msgBoxConfirm(message, {
                title: '',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                headerClass: 'd-none',
                footerClass: 'p-2 border-top-0',
                bodyClass: 'p-3',
                hideHeaderClose: false,
                centered: true,
            })) || false);
        }
        catch (err) {
            return false;
        }
    }
}
export default new UtilsService();
