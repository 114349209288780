import Axios from '../axios-instance';
// import ApexCharts from 'apexcharts';
import { API_URL } from '../api-url/api-url.service';
export * from './charts-default.factory';
const STORAGE_ENDPOINT = '/resource/storage';
const BACKUPS_RECORDS_DATA_ENDPOINT = '/resource/backups-record';
const BACKUPS_STORAGE_DATA_ENDPOINT = '/resource/backups-size';
const ORG_BACKUPS_COUNT_ENDPOINT = '/resource/number-backups';
const ORG_BACKUPS_SIZE_ENDPOINT = '/resource/total-backups-size';
const ORG_RECORDS_COUNT_ENDPOINT = '/resource/total-backups-record';
const ORG_BACKUPS_BY_STATUS_ENDPOINT = '/resource/backups-by-status';
const ORG_BACK_SIZE_BY_DATE_ENDPOINT = '/resource/backups-size-by-date';
const ORG_RECORDS_BY_DATE_ENDPOINT = '/resource/backups-record-by-date';
const ORG_SF_RECOURCES_ENDPOINT = '/resource/salesforce-resource';
export class ResourceChartsService {
    async getInstanceResources() {
        const { data } = await Axios.get(`${API_URL}${STORAGE_ENDPOINT}`);
        return data;
    }
    async getBackupsRecordsCount(orgID) {
        const { data } = await Axios.get(`${API_URL}${BACKUPS_RECORDS_DATA_ENDPOINT}?orgId=${orgID}`);
        return data;
    }
    async getBackupsSizes(orgID) {
        const { data } = await Axios.get(`${API_URL}${BACKUPS_STORAGE_DATA_ENDPOINT}?orgId=${orgID}`);
        return data;
    }
    async getSFOrgResources(orgID) {
        const { data } = await Axios.get(`${API_URL}${ORG_SF_RECOURCES_ENDPOINT}?orgId=${orgID}`);
        return data;
    }
    async getRecordsByDate(orgID) {
        const { data } = await Axios.get(`${API_URL}${ORG_RECORDS_BY_DATE_ENDPOINT}?orgId=${orgID}`);
        return data;
    }
    async getBackSizeByDate(orgID) {
        const { data } = await Axios.get(`${API_URL}${ORG_BACK_SIZE_BY_DATE_ENDPOINT}?orgId=${orgID}`);
        return data;
    }
    async getBackupsByStatus(orgID) {
        const { data } = await Axios.get(`${API_URL}${ORG_BACKUPS_BY_STATUS_ENDPOINT}?orgId=${orgID}`);
        return data;
    }
    async getOrgRecordsCount(orgID) {
        const { data } = await Axios.get(`${API_URL}${ORG_RECORDS_COUNT_ENDPOINT}?orgId=${orgID}`);
        return data;
    }
    async getOrgBackupsSize(orgID) {
        const { data } = await Axios.get(`${API_URL}${ORG_BACKUPS_SIZE_ENDPOINT}?orgId=${orgID}`);
        return data;
    }
    async getOrgBackupsCount(orgID) {
        const { data } = await Axios.get(`${API_URL}${ORG_BACKUPS_COUNT_ENDPOINT}?orgId=${orgID}`);
        return data;
    }
}
export default new ResourceChartsService();
