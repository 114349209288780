import UtilsService from '@/services/utils/utils.service';
export const MAIN_RESOURCES = {
    INSTANCE_RESOURCE: {
        series: [],
        labels: [],
        colors: ['#27ae60', '#df0e77'],
        tooltip: {},
        chart: {
            type: 'donut',
            height: 170,
            width: '100%',
        },
        title: {
            text: 'Disk usage',
            style: {
                color: '#002d3f',
            },
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10,
            },
        },
        grid: {
            padding: {
                bottom: -130,
            },
        },
        responsive: [
            {
                breakpoint: 1024,
                options: {
                    chart: {
                        height: 100,
                        width: '100%',
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
        noData: {
            text: 'Loading data',
            align: 'center',
            verticalAlign: 'top',
            style: {
                color: '#00a1e0',
                fontSize: '18px',
            },
        },
    },
    DATA_VOLUME: {
        RECORDS: {
            series: [],
            chart: {
                type: 'area',
                height: 170,
                stacked: true,
            },
            title: {
                text: 'Data Volume (#records)',
                style: {
                    color: '#002d3f',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: 1,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.6,
                    opacityTo: 0.8,
                },
            },
            legend: {
                show: false,
            },
            xaxis: {
                type: 'datetime',
            },
            noData: {
                text: 'Loading data',
                align: 'center',
                verticalAlign: 'top',
                style: {
                    color: '#00a1e0',
                    fontSize: '18px',
                },
            },
        },
        SIZE: {
            series: [],
            chart: {
                type: 'bar',
                height: 170,
                stacked: true,
                toolbar: {
                    show: true,
                },
                zoom: {
                    enabled: true,
                },
            },
            tooltip: {
                y: {
                    formatter: () => null,
                    title: {
                        formatter: (ser, data) => `${ser}: ${UtilsService.bytesToSizeString(data.series[0][data.dataPointIndex])}`,
                    },
                },
                marker: {
                    show: false,
                },
            },
            title: {
                text: 'Data Volume (#size)',
                style: {
                    color: '#002d3f',
                },
            },
            dataLabels: {
                enabled: false,
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0,
                        },
                    },
                },
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%',
                },
            },
            xaxis: {
                type: 'datetime',
            },
            legend: {
                show: false,
            },
            fill: {
                opacity: 1,
            },
            noData: {
                text: 'Loading data',
                align: 'center',
                verticalAlign: 'top',
                style: {
                    color: '#00a1e0',
                    fontSize: '18px',
                },
            },
        },
    },
    ORGS_DATA: {
        JOBS: {
            series: [],
            labels: [],
            colors: [],
            chart: {
                type: 'pie',
                width: 40,
                height: 40,
                sparkline: {
                    enabled: true,
                },
            },
            stroke: {
                width: 1,
            },
            tooltip: {
                fixed: {
                    enabled: false,
                },
            },
        },
        RECORDS: {
            series: [],
            chart: {
                type: 'line',
                width: 100,
                height: 35,
                sparkline: {
                    enabled: true,
                },
            },
            stroke: {
                width: 2,
            },
            tooltip: {
                fixed: {
                    enabled: false,
                },
                x: {
                    show: false,
                },
                y: {
                    title: {
                        formatter() {
                            return '';
                        },
                    },
                },
                marker: {
                    show: false,
                },
            },
        },
        SIZE: {
            series: [],
            chart: {
                type: 'bar',
                width: 200,
                height: 45,
                sparkline: {
                    enabled: true,
                },
            },
            stroke: {
                width: 1,
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                },
            },
            xaxis: {
                crosshairs: {
                    width: 1,
                },
            },
            tooltip: {
                fixed: {
                    enabled: false,
                },
                x: {
                    show: false,
                },
                y: {
                    formatter: () => null,
                    title: {
                        formatter: (_ser, data) => `${UtilsService.bytesToSizeString(data.series[0][data.dataPointIndex])}`,
                    },
                },
                marker: {
                    show: false,
                },
            },
        },
    },
};
const BACKUPS_CHARTS_STYLES = {
    title: {
        color: '#2c3e50',
        fontFamily: "'IBM Plex Sans', Helvetica, Arial, sans-serif",
        fontWeight: 'bold',
    },
};
export const BACKUPS_CHARTS = {
    API_CHART: {
        series: [],
        colors: ['#df0e77', '#00a1e0'],
        chart: {
            type: 'area',
            height: 240,
            width: '100%',
            stacked: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: [2, 3],
        },
        legend: {
            show: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: [0, 0.5],
                opacityTo: [0, 0],
                stops: [0, 90, 100],
            },
        },
        xaxis: {
            type: 'datetime',
        },
        title: {
            text: 'Daily API Request',
            style: BACKUPS_CHARTS_STYLES.title,
        },
        responsive: [],
        noData: {
            text: 'Loading data',
            align: 'center',
            verticalAlign: 'top',
            style: {
                color: '#00a1e0',
                fontSize: '18px',
            },
        },
    },
    BULK_CHART: {
        series: [],
        colors: ['#df0e77', '#00a1e0'],
        chart: {
            type: 'area',
            height: 240,
            width: '100%',
            stacked: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: [2, 3],
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: [0, 0.5],
                opacityTo: [0, 0],
                stops: [0, 90, 100],
            },
        },
        legend: {
            show: false,
        },
        xaxis: {
            type: 'datetime',
        },
        title: {
            text: 'Daily Bulk API',
            style: BACKUPS_CHARTS_STYLES.title,
        },
        responsive: [],
        noData: {
            text: 'Loading data',
            align: 'center',
            verticalAlign: 'top',
            style: {
                color: '#00a1e0',
                fontSize: '18px',
            },
        },
    },
    SIZE: {
        series: [],
        legend: {
            show: false,
        },
        chart: {
            height: 240,
            width: '100%',
            type: 'treemap',
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
            },
            formatter: (text) => {
                return [text];
            },
            offsetY: -4,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            treemap: {
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
            },
        },
        title: {
            text: 'Backups size',
            style: BACKUPS_CHARTS_STYLES.title,
        },
        responsive: [],
        noData: {
            text: 'Loading data',
            align: 'center',
            verticalAlign: 'top',
            style: {
                color: '#00a1e0',
                fontSize: '18px',
            },
        },
    },
    RECORDS: {
        series: [],
        legend: {
            show: false,
        },
        chart: {
            height: 240,
            width: '100%',
            type: 'treemap',
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
            },
            formatter: (text) => {
                return [text];
            },
            offsetY: -4,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            treemap: {
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
            },
        },
        title: {
            text: 'Backups records',
            style: BACKUPS_CHARTS_STYLES.title,
        },
        responsive: [],
        noData: {
            text: 'Loading data',
            align: 'center',
            verticalAlign: 'top',
            style: {
                color: '#00a1e0',
                fontSize: '18px',
            },
        },
    },
    SIZE_TIME: {
        series: [],
        chart: {
            height: 240,
            width: '100%',
            type: 'bar',
        },
        title: {
            text: 'Backups size',
            style: BACKUPS_CHARTS_STYLES.title,
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    fontSize: '12px',
                },
            },
        },
        tooltip: {
            y: {
                formatter: () => null,
                title: {
                    formatter: (_ser, data) => `${UtilsService.bytesToSizeString(data.series[0][data.dataPointIndex])}`,
                },
            },
            marker: {
                show: false,
            },
        },
        responsive: [],
        noData: {
            text: 'Loading data',
            align: 'center',
            verticalAlign: 'top',
            style: {
                color: '#00a1e0',
                fontSize: '18px',
            },
        },
    },
    RECORDS_TIME: {
        series: [],
        chart: {
            height: 240,
            width: '100%',
            type: 'bar',
        },
        title: {
            text: 'Backups records',
            style: BACKUPS_CHARTS_STYLES.title,
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    fontSize: '12px',
                },
            },
        },
        responsive: [],
        noData: {
            text: 'Loading data',
            align: 'center',
            verticalAlign: 'top',
            style: {
                color: '#00a1e0',
                fontSize: '18px',
            },
        },
    },
};
